<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    >
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
      />
    </b10-toolbar>
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :list-item-lines="3"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template
          slot="extraSubtitle"
        >
          <v-chip
            v-if="formattedItem.checklist_ot && formattedItem.checklist_ot.firma"
            label
            small
            color="info"
            text-color="white"
          >
            Firmado
          </v-chip>
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      >
        <template
          #avatar="slotProps"
        >
          <div
            v-if="slotProps.detail.data && slotProps.detail.data.grupo_checklist_ot"
            class="text-xs-center"
          >
            <v-avatar
              :color="slotProps.detail.data.avatarColor"
            >
              <span
                class="white--text font-weight-bold subtitle-1"
              >{{ slotProps.detail.data.avatar }}</span>
            </v-avatar>
          </div>
        </template>
      </b10-view-details>
      <checklist-view-legend
        :showing.sync="showingDialogs.legends"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.search"
        @click="clickBuscarMaterial"
      />
      <buscar-material-afectado
        :showing.sync="showingDialogs.buscarMaterialAfectado"
        :idparte-trabajo="routeParams.idparte_trabajo"
        @click-buscar-avanzado="clickBuscarMaterialAvanzado"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import { viewPageMixin } from '@/mixins/viewPageMixin'
import toolbarOptions from './toolbarOptions'
import _ from '@/utils/lodash'
import { nonEmpty } from '@/utils/templates'
import { get } from 'vuex-pathify'
import Data from './ChecklistViewData'
import { GRUPO_CHECKLIST, CHECKLIST, PARTE_TRABAJO } from '@/utils/consts'
import ChecklistViewLegend from './components/ChecklistViewLegend'
import BuscarMaterialAfectado from '../components/BuscarMaterialAfectado'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import { encodeBase64 } from '@/utils/router'

export default {
  components: {
    ChecklistViewLegend,
    BuscarMaterialAfectado,
    VolverParteChecklistMenuItems,
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      toolbarOptions: toolbarOptions(),
      moreInfoRows: [
        { name: 'checklist_ot.fchecklist', label: 'Fecha de finalización', filter: this.$options.filters.shortDate },
        { name: 'checklist_ot.firmante', label: 'Firmante' },
        { name: 'checklist_ot.ffirma', label: 'Fecha de firma', filter: this.$options.filters.shortDate },
        { name: 'checklist_ot.firmante_dni', label: 'NIF del firmante' },
        { name: 'checklist_ot.firmante_cargo', label: 'Cargo del firmante' },
        { name: 'checklist_ot.observaciones', label: 'Observaciones' },
      ],
      parteRelacionado: {},
      showingDialogs: {
        moreInfo: false,
        legends: false,
        buscarMaterialAfectado: false,
      },
      gruposLoaded: false,
      GRUPO_CHECKLIST,
      CHECKLIST,
    }
  },
  computed: {
    cronometroIdparte: get('cronometro/idparte'),
    usuarioIdtecnico: get('usuario/idtecnico'),
    networkOnline: get('network/online'),
    estadoChecklistPendiente () {
      return this.item.dataset.checklist_ot.estado === CHECKLIST.estados.pendiente
    },
    estadoChecklistFinalizado () {
      return this.item.dataset.checklist_ot.estado === CHECKLIST.estados.finalizado
    },
    estadoPartePendiente () {
      return this.parteRelacionado.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente
    },
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.checklist_ot.idchecklist_ot
        item.title = this.$options.filters.linebreaksBr(nonEmpty`
          Orden ${item.orden_trabajo.orden_trabajo_serie_numero} - Parte Nº${this.routeParams.idparte_trabajo}`)
        item.subtitle = item.checklist_ot.descripcion
        if (this.estadoChecklistPendiente && this.cronometroIdparte === parseInt(this.routeParams.idparte_trabajo)) {
          item.badge = 'Cronómetro iniciado'
          item.badgeColor = CHECKLIST.colores.enCurso
        } else if (this.estadoChecklistPendiente) {
          item.badge = 'Pendiente'
          item.badgeColor = CHECKLIST.colores.pendiente
        } else if (this.estadoChecklistFinalizado) {
          item.badge = 'Finalizado'
          item.badgeColor = CHECKLIST.colores.finalizado
        }
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  watch: {
    networkOnline (newValue) {
      this.toolbarOptions.irOrden.visible = !!newValue
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.parteRelacionado = await Data.rowParteTrabajo(this, this.routeParams.idparte_trabajo)
      this.title = `Checklist parte Nº${this.routeParams.idparte_trabajo}`
      this.initMenu()
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectChecklistOt(this, this.routeParams.idchecklist_ot)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      // anomalias
      const detailAnomalias = this.addDetail(
        'anomalias', 'Anomalías', 'Anomalías detectadas en el checklist', 'anomaliaChecklist'
      )
      const datasetAnomalias = await this.$offline.parteTrabajo.selectAnomaliasResumen(
        this.routeParams.idparte_trabajo, this.routeParams.idchecklist_ot
      )
      let countAnomalias = 0
      let totalsAnomalias = []
      for (const rowAnomalias of datasetAnomalias) {
        totalsAnomalias.push(`${rowAnomalias.grado_anomalia.alias} (${rowAnomalias.count})`)
        countAnomalias += rowAnomalias.count
      }
      detailAnomalias.badge = countAnomalias || 0
      if (countAnomalias > 0) {
        detailAnomalias.badgeColor = 'error'
      }
      detailAnomalias.totals = totalsAnomalias.join(', ')
      // acciones correctoras
      const detailAccionesCorrectoras = this.addDetail(
        'accionesCorrectoras', 'Acciones correctoras', 'Acciones correctoras realizadas o pendientes en el checklist', 'accionCorrectora'
      )
      const datasetAccionesCorrectoras = await this.$offline.parteTrabajo.selectAccionesCorrectorasResumen(
        this.routeParams.idparte_trabajo, this.routeParams.idchecklist_ot
      )
      detailAccionesCorrectoras.badge = datasetAccionesCorrectoras.count || 0
      const detailsGrupos = []
      // grupos generales
      const datasetGruposGeneral = await Data.selectGruposGeneral(this, this.routeParams.idchecklist_ot)
      for (const grupo of datasetGruposGeneral) {
        detailsGrupos.push({
          name: grupo.grupo_checklist_ot.idgrupo_checklist_ot,
          title: grupo.grupo_checklist_ot.descripcion,
          subtitle: '',
          icon: undefined,
          data: grupo,
          completado: (grupo.porcentajeProgreso === 100),
        })
      }
      // grupos subsistemas
      const datasetGruposSubsistema = await Data.selectGruposSubsistema(this, this.routeParams.idchecklist_ot)
      for (const grupo of datasetGruposSubsistema) {
        detailsGrupos.push({
          name: grupo.grupo_checklist_ot.idgrupo_checklist_ot,
          title: grupo.grupo_checklist_ot.descripcion,
          subtitle: `Subsistema ${grupo.desc_subsis}`,
          icon: undefined,
          data: grupo,
          completado: (grupo.porcentajeProgreso === 100),
        })
      }
      // grupos material
      const datasetGruposMaterial = await Data.selectGruposMaterial(this, this.routeParams.idchecklist_ot, this.routeParams.idparte_trabajo)
      for (const grupo of datasetGruposMaterial) {
        detailsGrupos.push({
          name: grupo.grupo_checklist_ot.idgrupo_checklist_ot,
          title: grupo.grupo_checklist_ot.descripcion,
          subtitle: `${grupo.desc_subsis} (${grupo.cantMateriales})`,
          icon: undefined,
          data: grupo,
          completado: (grupo.porcentajeProgreso === 100),
        })
      }
      // agregar grupos a details: pendientes/completados
      const gruposPendientes = _.filter(detailsGrupos, { completado: false })
      if (gruposPendientes.length > 0) {
        this.addDetailHeader('Grupos de preguntas sin contestar')
        for (const grupo of gruposPendientes) {
          const detail = this.addDetail(grupo.name, grupo.title, grupo.subtitle, grupo.icon, false, grupo.data)
          detail.badgeColor = grupo.data.color
          detail.badge = `${grupo.data.porcentajeProgreso}%`
        }
      }
      const gruposCompletados = _.filter(detailsGrupos, { completado: true })
      if (gruposCompletados.length > 0) {
        this.addDetailHeader('Grupos de preguntas contestados')
        for (const grupo of gruposCompletados) {
          const detail = this.addDetail(grupo.name, grupo.title, grupo.subtitle, grupo.icon, false, grupo.data)
          detail.badgeColor = grupo.data.color
          detail.badge = `${grupo.data.porcentajeProgreso}%`
        }
      }
    },
    initMenu () {
      this.toolbarOptions.irOrden.visible = !!this.networkOnline
      if (this.estadoChecklistPendiente) {
        this.toolbarOptions.pendiente.visible = false
        this.toolbarOptions.finalizar.visible = true
        this.toolbarOptions.marcarTodoCorrecto.visible = this.hasViewPerm(this.permissions.partes.marcarPreguntasTodoCorrecto)
      } else {
        this.toolbarOptions.finalizar.visible = false
        this.toolbarOptions.pendiente.visible = true
        this.toolbarOptions.marcarTodoCorrecto.visible = false
      }
      if (this.estadoPartePendiente && this.cronometroIdparte === parseInt(this.routeParams.idparte_trabajo)) {
        this.toolbarOptions.iniciarCrono.visible = false
        this.toolbarOptions.detenerCrono.visible = true
        this.toolbarOptions.resumenYFirma.visible = false
      } else if (this.estadoPartePendiente && !this.cronometroIdparte) {
        this.toolbarOptions.iniciarCrono.visible = true
        this.toolbarOptions.detenerCrono.visible = false
        this.toolbarOptions.resumenYFirma.visible = false
      } else {
        this.toolbarOptions.iniciarCrono.visible = false
        this.toolbarOptions.detenerCrono.visible = false
        this.toolbarOptions.resumenYFirma.visible = true
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'anomalias') {
        this.$appRouter.push({
          name: 'offline__anomalia-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: this.routeParams.idchecklist_ot,
          },
        })
      } else if (data.detail.name === 'accionesCorrectoras') {
        this.$appRouter.push({
          name: 'offline__accion-correctora-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: this.routeParams.idchecklist_ot,
          },
        })
      } else if (!_.isEmpty(data.detail.data) && data.detail.data.grupo_checklist_ot) {
        const clasifGrupo = data.detail.data.grupo_checklist_ot.clasificacion
        if ([GRUPO_CHECKLIST.clasificacion.generales, GRUPO_CHECKLIST.clasificacion.subsistemas].includes(clasifGrupo)) {
          this.$appRouter.push({
            name: 'offline__grupos-pregunta-list',
            params: {
              idparte_trabajo: this.routeParams.idparte_trabajo,
              idchecklist_ot: this.routeParams.idchecklist_ot,
              idgrupo_checklist_ot: data.detail.data.grupo_checklist_ot.idgrupo_checklist_ot,
            },
          })
        } else if (clasifGrupo === GRUPO_CHECKLIST.clasificacion.materiales) {
          this.$appRouter.push({
            name: 'offline__lparte-trabajo-material-afectado-list',
            params: {
              idparte_trabajo: this.routeParams.idparte_trabajo,
            },
            query: {
              idgrupo_checklist_ot: data.detail.data.grupo_checklist_ot.idgrupo_checklist_ot,
            },
          })
        }
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.marcarTodoCorrecto) {
        this.clickMarcarTodoCorrecto()
      } else if (option === this.toolbarOptions.pendiente) {
        this.clickPasarAPendiente()
      } else if (option === this.toolbarOptions.finalizar) {
        this.clickFinalizar()
      } else if (option === this.toolbarOptions.parteTrabajo) {
        this.$appRouter.push({
          name: 'offline__parte-trabajo-view',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo
          }
        })
      } else if (option === this.toolbarOptions.resumenYFirma) {
        this.clickResumenYFirma()
      } else if (option === this.toolbarOptions.iniciarCrono) {
        this.$appRouter.push({
          name: 'offline__cronometro-iniciar',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo
          }
        })
      } else if (option === this.toolbarOptions.detenerCrono) {
        this.$appRouter.push({
          name: 'offline__cronometro-detener'
        })
      } else if (option === this.toolbarOptions.irOrden) {
        this.$appRouter.push({
          name: 'ordenes__orden-trabajo-view',
          params: {
            idorden_trabajo: this.item.dataset.orden_trabajo.idorden_trabajo,
          },
        })
      }
    },
    async clickMarcarTodoCorrecto () {
      const res = await this.$alert.showConfirm(
        `¿Deseas marcar todas las preguntas no contestadas como correctas?`
      )
      if (res) {
        this.$loading.showManual('Marcando todo correcto')
        try {
          await this.$offline.checklistOt.updateChecklistContestadoCorrecto(
            this.routeParams.idparte_trabajo,
            this.routeParams.idchecklist_ot,
            this.usuarioIdtecnico
          )
          await this.loadPage()
        } finally {
          this.$loading.hide()
        }
      }
    },
    async clickFinalizar () {
      if (this.cronometroIdparte !== this.routeParams.idparte_trabajo) {
        this.$appRouter.push({
          name: 'offline__parte-finalizar',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo
          }
        })
      } else {
        this.$alert.showSnackbarError('Debes detener el cronómetro para finalizar')
      }
    },
    async clickPasarAPendiente () {
      const res = await this.$alert.showConfirm(
        `¿Deseas cambiar el checklist al estado "pendiente" ?`
      )
      if (res) {
        await this.$offline.checklistOt.reabrirChecklistOt(this.routeParams.idchecklist_ot)
        await this.loadPage()
        this.initMenu()
      }
    },
    clickResumenYFirma () {
      this.$appRouter.push({
        name: 'offline__parte-resumen-cliente-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
      })
    },
    clickBuscarMaterial () {
      this.showingDialogs.buscarMaterialAfectado = !this.showingDialogs.buscarMaterialAfectado
    },
    clickBuscarMaterialAvanzado () {
      this.showingDialogs.buscarMaterialAfectado = false
      this.$appRouter.push({
        name: 'offline__lparte-trabajo-material-afectado-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
        query: {
          _show_filter: encodeBase64(true)
        },
      })
    },
  },
}
</script>
